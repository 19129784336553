import type { Cart } from '#root/shared/types/graphql-types';
import { defineStore } from 'pinia';

export const useCheckoutStore = defineStore('checkout', {
  state: (): {
    email: string;
    enableCartUpdates: boolean;
    postalCode: string;
    showConfirmInvalidPromo: boolean;
    selectedShippingOptionSlug: string;
    shippingRate: null | {
      rate: number;
      baseRate: number;
      amountToGetFreeShipping: number;
      amountLeftForFreeShipping: number;
    };
  } => ({
    email: '',
    enableCartUpdates: true,
    postalCode: '',
    showConfirmInvalidPromo: false,
    selectedShippingOptionSlug: '',
    shippingRate: null,
  }),

  actions: {
    async getShippingRate(
      cartAmount: number,
      shippingProviderSlug: string,
      cart?: Cart | null
    ) {
      try {
        const rates = await useNuxtApp().$apiFetch('/rest/shipping-rate', {
          method: 'POST',
          body: {
            cartAmount,
            shippingProviderSlug,
            cart,
          },
        });
        this.shippingRate = rates;
        return rates;
      } catch (error) {
        return error;
      }
    },

    setEmail(payload: string) {
      this.email = payload;
    },
    setEnableCartUpdates(payload: boolean) {
      this.enableCartUpdates = payload;
    },
    setPostalCode(payload: string) {
      this.postalCode = payload;
    },
    setSelectedShippingOptionSlug(payload: string) {
      this.selectedShippingOptionSlug = payload;
    },
    setShowConfirmInvalidPromo(payload: boolean) {
      this.showConfirmInvalidPromo = payload;
    },
  },
});
